import { PACKAGING_INSURANCE_FILES } from "./constants";

const onClickingPackagingAndInsurance = () => {
  PACKAGING_INSURANCE_FILES.forEach((file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}

const adaptMobilePhone = (phone) => {
  if(!phone || phone === 'null') return '';

  const isEnglishNumber = /^[0-9]+/.test(phone)
  const isArabicNumber = /^[\u0660-\u0669]+/.test(phone)

  if(isEnglishNumber){
    if(phone[0] !== '0'){
      return `0${phone}`
    }

    return phone
  }

  if(isArabicNumber) {
    if(phone[0] !== '٠') {
      return `.${phone}`
    }
    return phone
  }

  return phone
}
export { onClickingPackagingAndInsurance, adaptMobilePhone }
import React, { Fragment } from "react"

function PrintLabels({ labels }) {

  if (Array.isArray(labels)) {
    const filteredLabels = labels.filter((label) => label !== '')

    return (
      filteredLabels.map((label, index) => (
        <Fragment key={label}>
          <bdi>{label}</bdi>
          {index < (filteredLabels.length - 1) && ' - '}
        </Fragment>
      )
      )
    )
  }

  return labels
}

export default PrintLabels
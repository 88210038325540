import 'react-datasheet-grid/dist/style.css'

import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DataSheetGrid, keyColumn, } from 'react-datasheet-grid'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material'

import {
  createBulkOrders, customColumn, getAdaptedData, getDropdownData, isRowUpdated, selectColumn, validateOrders
} from './bulk-create-order.utils'
import { fetchCustomAreas, fetchLocations, fetchOrdersTypes, fetchPackageTypes } from '../create-order/create-order.slice'
import { Select } from '../../common/components/form-field/components/select/select';
import { COLUMNS, INIT_DATA_SUMMARY, INIT_ROWS, SUMMARY_IDS, VALIDATED_STATUS } from './bulk-create-orders.constants'
import { fetchAreas, selectAuthData } from '../../utils/common.slice'
import { BlackTooltip } from '../../common/components/black-tooltip'
import IconButton from '../../common/components/icon-button'
import { CourierIcon, CrossIcon, DeleteIcon } from '../../assets/icons'
import ColumnHeaderCell from './column-header-cell'
import Button from '../../common/components/button'
import Styled from './bulk-create-order.styled'
import UploadFileView from './upload-file-view';
import UploadFileViewHeader from './upload-file-view/upload-file-view-header';
import DeleteRowDialog from './components/delete-row-dialog';
import { PERMS_IDS } from '../../constants';
import './table.css'
import useViewport from '../../utils/use-viewport';
import MobileErrorView from '../../common/components/Mobile-error-view';


function BulkCreateOrders() {
  const { isMobileView } = useViewport();
  const { t } = useTranslation();

  const [data, setData] = useState(INIT_ROWS)
  const [allAreas, setAllAreas] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dropDownOptions, setDropDownOptions] = useState([])
  const [pickupLocations, setPickupLocations] = useState([])
  const [currentPickupLocation, setCurrentPickupLocation] = useState({})
  const [dataSummary, setDataSummary] = useState(INIT_DATA_SUMMARY)
  const [deleteRowModalState, setDeleteRowModalState] = useState({ isOpen: false, rowData: {}, deleteRow: () => {} })
  const [isOrdersValidatedOnce, setIsOrdersValidatedOnce] = useState(false);
  const [createdOrdersCount, setCreatedOrdersCount] = useState(0)
  const [isValidating, setIsValidating] = useState(false)

  const theme = useTheme();
  const dispatch = useDispatch();

  const abortController = useRef({ abort: () => {} })

  const authData = useSelector(selectAuthData)

  const isUploadView = useMemo(() =>  (authData?.permissions || []).includes(PERMS_IDS.BULK_UPLOAD_VIEW), [authData])
  const isCustomMerchant = useMemo(() =>  authData.customizationType === 'custom', [authData])
  const isCitiesOnlyMerchant = useMemo(() =>  authData.customizationType === 'cities_only', [authData])
  const isSpecialMerchant = useMemo(() => isCitiesOnlyMerchant || isCustomMerchant, [isCustomMerchant, isCitiesOnlyMerchant])
  const updatedOrdersLength = useMemo(() => data.filter((row) => isRowUpdated(row))?.length, [data])

  useEffect(() => {
    let promises = [
      dispatch(fetchAreas()),
      dispatch(fetchLocations()),
      dispatch(fetchOrdersTypes()),
      dispatch(fetchPackageTypes()),
    ]
    setIsOrdersValidatedOnce(false)
    setDataSummary(INIT_DATA_SUMMARY)
    if(isCustomMerchant) promises.push(dispatch(fetchCustomAreas()))
    if(isUploadView) { setData([]) } else { setData(INIT_ROWS) }

    setIsLoading(true)
    Promise.all(promises).then((res) => {
      const { options, allAreas = [] } = getDropdownData(res)
      setIsLoading(false)
      setAllAreas(allAreas)
      setDropDownOptions(options)
      setPickupLocations(options.pickupLocations)
      setCurrentPickupLocation(options.pickupLocations?.[0])
    })
  }, [authData]);

  const mappedColumns = useMemo(() => COLUMNS.map(({ key, type, noInfo, optionsId, subType, minWidth, isOptional }) => {
    const column = type === 'custom' ?
      customColumn({ subType, theme, key }) :
      selectColumn({ options: dropDownOptions[optionsId], key, theme, isSpecialMerchant, allAreas, isUploadView })

    return ({
      ...keyColumn(key, column),
      title: <ColumnHeaderCell key={key} i18nKey={key} t={t} theme={theme} noInfo={noInfo} isOptional={isOptional} />,
      minWidth,
      renderWhenScrolling: false,
    })
  }), [isSpecialMerchant, dropDownOptions, allAreas, isSubmitting])

  const updateData = async (updatedOrders) => {
    if(updatedOrders?.length === 0) return

    setIsValidating(true)
    setCreatedOrdersCount(0)
    setIsSubmitting(true)
    const orders = getAdaptedData(updatedOrders)
    setData(orders)
    //Set init values to automatically filled cells
    //validate orders
    const validatedData = await validateOrders({
      dispatch, currentPickupLocationId: currentPickupLocation.value, orders, theme,  t, isSpecialMerchant, allAreas, isCreationSkipped: !isOrdersValidatedOnce, abortController, setCreatedOrdersCount
    })

    const { validatedOrders, erroredRowsIndices, areaValidationStatus } = validatedData
    //update grid data with errored data
    setData(validatedOrders)

    const onValidation = (msg, isSuccess) => {
      if (isSuccess) {
        toast(msg)
      } else {
        toast.error(msg)
      }
      setIsSubmitting(false)
      return
    }

    const updateSummary = (createdOrdersCount, failedOrdersCount) => setDataSummary((prevSummary) => ({
      ...prevSummary, [SUMMARY_IDS.CREATED]: prevSummary[SUMMARY_IDS.CREATED] + createdOrdersCount,  [SUMMARY_IDS.FAILED]: failedOrdersCount
    }))

    if(!isOrdersValidatedOnce) {
      setIsOrdersValidatedOnce(true)
      if(erroredRowsIndices.length > 0) return onValidation(`${erroredRowsIndices.length} orders with invalid data. Fix errors to successfully create your orders.`);
      if(areaValidationStatus === VALIDATED_STATUS.ALL_VALIDATED) return onValidation(t('bulkCreateOrders.successMsgs.allCitiesVerified'), true);
      if(areaValidationStatus === VALIDATED_STATUS.SOME_FAILED) return onValidation(t('bulkCreateOrders.errorMsgs.citiesVerificationFailed'));
    }

    const onError = (updatedOrders, failedOrdersCount = 0, createdOrdersCount = 0, isAborted = false) => {
      if(!isAborted) {
        setData(updatedOrders);
        setIsSubmitting(false);
      }

      if(isUploadView) updateSummary(createdOrdersCount, failedOrdersCount)
    }

    const onSuccess = (updatedOrders, isAborted) => {
      if(!isAborted) {
        setData(updatedOrders);
        setIsSubmitting(false);
      }
      if(isUploadView) {
        setDataSummary(INIT_DATA_SUMMARY)
        setData([])
        setIsOrdersValidatedOnce(false)
      }
    }
    setIsValidating(false)
    createBulkOrders({  dispatch, orders: validatedOrders, abortController, currentPickupLocationId: currentPickupLocation.value, onError, onSuccess, setCreatedOrdersCount })
  }

  if(isMobileView) return <MobileErrorView errorMsg={t('bulkCreateOrders.notAvailable')} />

  const renderRowIndicator = (props) =>(
    <Typography variant="regular" color={theme.palette[props?.rowData?.isError? 'red': 'black']}>
      {props.rowIndex + 1}
    </Typography>
  )

  if(isLoading) return (
    <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress color="secondary" />
    </Box>
  )

  const handleOnChange = (newValue) => {
    setData(newValue);
  }

  const onLeave = () => {
    abortController.current.abort()
    setData([])
    setIsOrdersValidatedOnce(false)
    setDataSummary(INIT_DATA_SUMMARY)
  }

  const renderGrid = () => (
    <Stack display="flex" gap="16px" overflow="auto">
      <Styled.GridWrapper disabled={isSubmitting}>
        {isSubmitting &&
        // hack: Add div above sheet to disable clicking on it
        <Box height="calc(100% - 300px)" width="calc(100% - 150px)" position="absolute" onClick={(e) => e.stopPropagation()} zIndex="22"/>
        }
        <DataSheetGrid
          value={data}
          onChange={handleOnChange}
          addRowsComponent={null}
          columns={mappedColumns}
          stickyRightColumn={{
            data: data,
            component: ({ deleteRow, rowData }) => (
              <Styled.DeleteIconButton disabled={!isRowUpdated(rowData)} onClick={() => setDeleteRowModalState({ isOpen: true, rowData, deleteRow })}>
                <DeleteIcon />
              </Styled.DeleteIconButton>
            ),
          }}
          gutterColumn={{
            component: renderRowIndicator,
          }}
        />
      </Styled.GridWrapper>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="semiBoldSmall" color={theme.palette.gray}>
          {t('bulkCreateOrders.validateOrdersFooterMsg')}
        </Typography>
  
        <Box display="flex" gap="8px" justifyContent='flex-end'>
          {/* <Button size={{ width: 'fit-content' }} color="secondary" label="Print labels" isDisable={createdOrdersIds.length === 0}/> */}
          <Button
            size={{ width: isSubmitting? '144px': 'fit-content', height: '36px' }}
            label={t('bulkCreateOrders.verifyAndUploadButton')}
            onClick={() => updateData(data)}
            isLoading={isSubmitting}
            loadingLabel={!isValidating && `${createdOrdersCount}/${updatedOrdersLength} orders..`}
            isDisabled={updatedOrdersLength === 0}
          />
        </Box>
      </Box>

      <DeleteRowDialog t={t} deleteRowModalState={deleteRowModalState} setDeleteRowModalState={setDeleteRowModalState} setDataSummary={setDataSummary}  />
    </Stack>
  )

  const renderView = () => {
    if(isUploadView && data.length > 0) return renderGrid()

    if(isUploadView && data.length === 0) return (
      <UploadFileView
        pickupLocations={pickupLocations}
        currentPickupLocation={currentPickupLocation}
        setCurrentPickupLocation={setCurrentPickupLocation}
        setPickupLocations={setPickupLocations}
        dropDownOptions={dropDownOptions}
        setData={setData}
        setSummary={setDataSummary}
        setDropDownsOptions={setDropDownOptions}
        updateData={updateData}
        isSpecialMerchant={isSpecialMerchant}
        allAreas={allAreas}
        toast={toast}
        theme={theme}
      />
    )

    return (
      <>
        <Box display="flex" width='100%' justifyContent='space-between' alignItems="center">
          <Box width="210px" display="flex" height="20px" alignItems='center' gap="8px">
            <CourierIcon color={theme.palette.black} />
            <Select
              noBorders
              noLeftPadding
              options={pickupLocations}
              value={currentPickupLocation}
              onChange={setCurrentPickupLocation}
              height={20}
              width={180}
            />
          </Box>

          <BlackTooltip title={t('bulkCreateOrders.clearTooltip')} placement="top">
            <Box>
              <IconButton icon={<CrossIcon color={theme.palette.gray} />} onClick={() => {setData(INIT_ROWS);}} />
            </Box>
          </BlackTooltip>
        </Box>
        {renderGrid()}
      </>
    )
  }

  return (
    <Styled.Wrapper>
      {isUploadView && data.length > 0 ?
        ( <UploadFileViewHeader
          t={t}
          dataSummary={dataSummary}
          toast={toast}
          data={data}
          options={dropDownOptions}
          onLeave={onLeave}
          updatedOrdersLength={updatedOrdersLength}
        />
        ) :
        <Typography variant="bold">{t('bulkCreateOrders.title')}</Typography>
      }
      {renderView()}
    </Styled.Wrapper>
  )
}

export default BulkCreateOrders
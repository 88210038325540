import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";

import useViewport from "../../../../utils/use-viewport";
import { SelectCourierCardsDetailed } from "../../../../common/components/select-courier-cards-detailed";
import { stableSort, getComparator } from "../../../../utils/sortArray";
import { SelectCourierCards } from "../../../../common/components/select-courier-cards";
import { courierSortingBy } from "../../../select-courier/select-courier.constants";
import Button from "../../../../common/components/button";
import Styled from "../components.styled";
import { updateMultiOrdersCouriers, updateSingleOrderCourier } from "./edit-courier-drawer.utils";

const EditCourier = ({ couriers, selectedOrders, closeDrawer, isLoading, setOrders, isSelectAll }) => {
  const { isMobileView } = useViewport();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedCourierId, setSelectedCourierId] = useState("");
  const [selectedSortByIndex, setSelectedSortByIndex] = useState("");
  const [isUpdatingCourier, setIsUpdatingCourier] = useState(false)
  const [orderBy, setOrderBy] = useState("");

  const visibleRows = useMemo(
    () => stableSort(couriers, getComparator(`${orderBy === 'success_rate'? 'desc': 'asc'}`, orderBy)),
    [`${orderBy === 'success_rate'? 'desc': 'asc'}`, orderBy, couriers]
  );

  const onClickSortBy = ({ key, index }) => {
    setOrderBy(key);
    setSelectedSortByIndex(index);
  };

  const updateCouriers = () => {
    setIsUpdatingCourier(true)
    const mutualParams = { dispatch, setIsUpdatingCourier, selectedOrders, selectedCourierId, setOrders, couriers, toast, closeDrawer }

    if (selectedOrders.length === 1 && !isSelectAll) {
      updateSingleOrderCourier(mutualParams)
    } else {
      updateMultiOrdersCouriers(mutualParams)
    }
  };

  const renderSortBy = () => {
    return (
      <Styled.EditCourierSortByGrid>
        <Typography
          variant="semiBoldSmall"
          color={theme.palette.gray}
          width={"fit-content"}
        >
          {t('selectCourier.sortBy.title')}
        </Typography>
        {courierSortingBy.map(({ key }, index) => (
          <Styled.EditCourierSortByItem
            key={key}
            onClick={() => onClickSortBy({ key, index })}
            $selected={index === selectedSortByIndex}
          >
            <Typography
              variant="boldSmall"
              color={
                index === selectedSortByIndex
                  ? theme.palette.black
                  : theme.palette.gray
              }
            >
              {t(`selectCourier.sortBy.options.${key}`)}
            </Typography>
          </Styled.EditCourierSortByItem>
        ))}
      </Styled.EditCourierSortByGrid>
    );
  };

  return (
    <Box height='100%'>
      {isLoading? (
        <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress color="secondary"/>
        </Box>
      ) : (
        <Styled.EditCourierContent $isMobileView={isMobileView}>
          <Styled.EditCourierSubContent $isMobileView={isMobileView}>
            {isMobileView || selectedOrders.length > 1 || isSelectAll ? null : renderSortBy()}
            <Styled.EditCourierListContent $isMobileView={isMobileView}>
              {isMobileView || selectedOrders.length > 1 || isSelectAll ? (
                <SelectCourierCards
                  t={t}
                  rows={visibleRows}
                  selectedCourierId={selectedCourierId}
                  setSelectedCourierId={setSelectedCourierId}
                />
              ) : (
                <SelectCourierCardsDetailed
                  rows={visibleRows}
                  selectedCourierId={selectedCourierId}
                  setSelectedCourierId={setSelectedCourierId}
                />
              )}
            </Styled.EditCourierListContent>
          </Styled.EditCourierSubContent>
          <Styled.EditCourierActionButtonContent>
            <Button
              label={t('selectCourier.updateCourierButton')}
              isDisabled={!selectedCourierId}
              onClick={updateCouriers}
              size={{ width: '100%' }}
              isLoading={isUpdatingCourier}
            />
          </Styled.EditCourierActionButtonContent>
        </Styled.EditCourierContent>
      )}
    </Box>
  );
};

export default EditCourier;

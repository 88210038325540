import GuidLinePdf from './assets/pdfs/Packaging Guide - Arabic.pdf'
import InsurancePdf from './assets/pdfs/Insurance & Compensations.pdf'

const FIELDS_TYPES = {
  TEXT: 'text',
  MULTILINE: 'multiline',
  NUMBER: 'number',
  NESTED_DROP_DOWN: 'nestedDropDown',
  CHECKBOX: 'checkbox',
  SELECT: 'select'
}

const ARABIC_REGEX = /[\u0600-\u06FF]/;

const ROWS_PER_PAGE_COUNT = 150;

const PERMS_IDS = { ACTION_CENTER: "action center",  ANALYTICS: 'analytics', PRINT_OVERLAY_VIEW: 'print-overlay-view', BULK_UPLOAD_VIEW: 'bulk-upload-view' }

const PACKAGING_INSURANCE_FILES = [
  { name: 'Packaging Guide.pdf', url: GuidLinePdf },
  { name: 'Insurance & Compensations.pdf', url: InsurancePdf }
];

export { FIELDS_TYPES, ARABIC_REGEX, PERMS_IDS, PACKAGING_INSURANCE_FILES, ROWS_PER_PAGE_COUNT }
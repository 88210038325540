import dayjs from "dayjs";
import { Parser } from "@json2csv/plainjs";

import {
  ACTIVITIES_IDS,
  ORDERS_ACTIONS_IDS,
  STATUS_IDS,
} from "./orders-table.constants";
import {
  printManifestoAction,
  exportOrdersAction,
} from "./orders.slice";
import toast from "react-hot-toast";

const createData = (
  id,
  code,
  creationDate,
  creationTime,
  createdBy,
  creationPrice,
  courierKey,
  courierPrice,
  processedToCountry,
  processedToStatus,
  processedToDate,
  processedToTime,
  status,
  statusTime,
  statusDate,
  attemptsCount,
  actions
) => ({
  id,
  code,
  creationDate,
  creationTime,
  createdBy,
  creationPrice,
  courierKey,
  courierPrice,
  processedToCountry,
  processedToStatus,
  processedToDate,
  processedToTime,
  attemptsCount,
  status,
  statusTime,
  statusDate,
  actions,
});

const getFormattedDate = (dateToParse) =>
  dateToParse ? dayjs(new Date(dateToParse)).format("ddd, D MMM") : "";
const getFormattedTime = (dateToParse) =>
  dateToParse ? dayjs(new Date(dateToParse)).format("h:mm A") : "";

const toUpperFirstLetter = (string) =>
  string[0].toUpperCase() + string.substring(1);

const getSelectedOrdersIndices = (selectedIndices, id) => {
  const selectedIndex = selectedIndices.indexOf(id);
  let newSelected = [];

  switch (selectedIndex) {
  case -1:
    newSelected = newSelected.concat(selectedIndices, id);
    break;

  case 0:
    newSelected = newSelected.concat(selectedIndices.slice(1));
    break;

  case selectedIndices.length - 1:
    newSelected = newSelected.concat(selectedIndices.slice(0, -1));
    break;

  default: // > 0
    newSelected = newSelected.concat(
      selectedIndices.slice(0, selectedIndex),
      selectedIndices.slice(selectedIndex + 1)
    );
    break;
  }

  return newSelected;
};

const getOrdersStatusCount = (orders, status) =>
  orders?.filter((order) => order.status === status)?.length || 0;

const getStatusTabDetails = ({ key, index }) => ({
  key,
  getStatusCount: (orders) => getOrdersStatusCount(orders, key),
  index,
});

const checkIfOrderSelected = (indices, id) => indices.indexOf(id) !== -1;

const getDeletedOrdersBEParams = (currentSelectedOrders) => {
  let selectedOrderIds = currentSelectedOrders.map(({ id }) => id);
  let selectedOrderCodes = currentSelectedOrders.map(({ code }) => code);
  let paramObject = {};
  paramObject = { selectedOrderIds, selectedOrderCodes };

  return paramObject;
}

const getActivities = ({ needDataUpdate, supportNote, needReprintLabel }) => [
  { type: ACTIVITIES_IDS.UPDATE, enabled: needDataUpdate },
  {
    type: ACTIVITIES_IDS.NOTE,
    hint: supportNote,
    enabled: Boolean(supportNote),
  },
  //TODO: readd when BE is implemented
  // { type: ACTIVITIES_IDS.REPRINT, hint: 'Reprint label', enabled: needReprintLabel }
];

const getUpdatedSummary = (prevOrdersSummary, statusFilter, newOrdersCount) => {
  let newSummary = {};

  Object.keys(prevOrdersSummary).forEach((key) => {
    if (key == statusFilter || key === STATUS_IDS.ALL) {
      return (newSummary = { ...newSummary, [key]: newOrdersCount });
    }

    return (newSummary = { ...newSummary, [key]: 0 });
  });

  return newSummary;
};

const handlePrintManifesto = (
  orders,
  dispatch,
) => {
  const parameterObject = {};
  const ordersIds = orders.map((order) => order.id);
  parameterObject.filters = { ids: ordersIds };


  dispatch(printManifestoAction(parameterObject)).then((response) => {
    if (response?.payload) {
      openFileInNewTab(response?.payload?.data);
    }
  });
};

const parseOrderToCSVRow = (order, fields) => {
  if (order.createdAt) {
    order.createdAt = `${getFormattedDate(order.createdAt)}, ${getFormattedTime(
      order.createdAt
    )}`;
  }
  if (order.processedOnDate) {
    order.processedOnDate = `${getFormattedDate(
      order.processedOnDate
    )}, ${getFormattedTime(order.processedOnDate)}`;
  }
  if (order.endDate) {
    order.endDate = `${getFormattedDate(order.endDate)}, ${getFormattedTime(
      order.endDate
    )}`;
  }
  if (order.description) {
    order.description = order.description.replaceAll("\n", "");
  }
  if (order.addressLine) {
    order.addressLine = order.addressLine.replaceAll("\n", "");
  }
  if (order.shippingNotes) {
    order.shippingNotes = order.shippingNotes.replaceAll("\n", "");
  }

  const parser = new Parser({ fields });
  const csvRow = parser.parse(order);
  return csvRow;
};

const handleExportSelectedOrders = (
  selectedTab,
  selectedOrders,
  isSelectAll,
  searchTerm,
  activeFilters,
  dispatch,
  t,
) => {
  const parameterObject = {};
  if (isSelectAll) {
    const filters = {
      ...activeFilters,
      status: selectedTab,
    };
    if (searchTerm) filters.code = searchTerm;
    parameterObject.filters = filters;
  } else {
    parameterObject.filters = { ids: selectedOrders.map((order) => order.id) };
  }
  dispatch(exportOrdersAction(parameterObject)).then((response) => {
    const orders = response?.payload?.orders;
    const isOverLimit = response?.payload?.isOverLimit;
    if (orders?.length > 0) {
      try {
        const csvData = [];
        const fields = generateExportOrdersFields(orders);
        orders.forEach((order) => {
          const csvRow = parseOrderToCSVRow(order, fields);
          csvData.push(csvRow);
        });
        exportCSVFile(csvData);
        if(isOverLimit) toast(t('listing.errorMsgs.exportLimitReached'))
      } catch (err) {
        console.error(err);
      }
    }
  });
};

const getOrderAction = ({
  isSelectAll,
  activeFilters,
  searchTerm,
  selectedOrders,
  selectedTab,
  dispatch,
  handleEditOrder,
  handleEditCourier,
  handleDeleteOrder,
  handlePrintLabel,
  t
}) => ({
  [ORDERS_ACTIONS_IDS.EDIT]: () => handleEditOrder(selectedOrders),
  [ORDERS_ACTIONS_IDS.EDIT_COURIER]: () => handleEditCourier(selectedOrders),
  [ORDERS_ACTIONS_IDS.PRINT_LABEL]: () => handlePrintLabel(),
  [ORDERS_ACTIONS_IDS.PRINT_MANIFESTO]: () =>
    handlePrintManifesto(selectedOrders, dispatch),
  [ORDERS_ACTIONS_IDS.EXPORT]: () => {
    return handleExportSelectedOrders(
      selectedTab,
      selectedOrders,
      isSelectAll,
      searchTerm,
      activeFilters,
      dispatch,
      t
    );
  },
  [ORDERS_ACTIONS_IDS.DELETE]: () => handleDeleteOrder(),
});

const openFileInNewTab = (fileData) => {
  const file = new Blob([fileData], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

const exportOrdersHeaders = {
  orderCode: "Order Code",
  creationDate: "Creation Date",
  orderType: "Order Type",
  pickupLocation: "Pickup Location",
  amount: "Amount Value (EGP)",
  noOfItems: "No. of Items [Delivery Package]",
  weight: "Weight (kgs) [Delivery Package]",
  description: "Description [Delivery Package]",
  openPackageAllowed: "Open Package Allowed",
  customerName: "Customer Name",
  phoneNumber: "Phone Number",
  backupPhoneNumber: "Backup Phone Number",
  shippingNotes: "Shipping Notes",
  refNumber: "Reference Number",
  city: "City",
  area: "Area",
  addreddLine: "Address Line",
  courier: "Courier",
  trackingNumber: "Tracking Number",
  shippingFees: "Total Shipping Fees (EGP)",
  processingDate: "Processing Date",
  status: "Main Status",
  subStatus: "Sub Status",
  rejectionReason: "Failed Attempt Reason",
  supportNote: "Support Note",
  endDate: "End Status Date",
};

const generateExportOrdersFields = () => {
  const fields = [
    { label: exportOrdersHeaders.orderCode, value: "code" },
    { label: exportOrdersHeaders.creationDate, value: "createdAt" },
    { label: exportOrdersHeaders.orderType, value: "type" },
    { label: exportOrdersHeaders.pickupLocation, value: "pickupLocationName" },
    { label: exportOrdersHeaders.amount, value: "amount" },
    { label: exportOrdersHeaders.noOfItems, value: "noOfItems" },
    { label: exportOrdersHeaders.weight, value: "weight" },
    { label: exportOrdersHeaders.description, value: "description" },
    {
      label: exportOrdersHeaders.openPackageAllowed,
      value: "openPackageAllowed",
    },
    { label: exportOrdersHeaders.customerName, value: "customerName" },
    { label: exportOrdersHeaders.phoneNumber, value: "phoneNumber" },
    {
      label: exportOrdersHeaders.backupPhoneNumber,
      value: "backupPhoneNumber",
    },
    { label: exportOrdersHeaders.shippingNotes, value: "shippingNotes" },
    { label: exportOrdersHeaders.refNumber, value: "refNumber" },
    { label: exportOrdersHeaders.city, value: "city" },
    { label: exportOrdersHeaders.area, value: "area" },
    { label: exportOrdersHeaders.addreddLine, value: "addressLine" },
    { label: exportOrdersHeaders.courier, value: "courierName" },
    { label: exportOrdersHeaders.trackingNumber, value: "externalId" },
    { label: exportOrdersHeaders.shippingFees, value: "fees" },
    { label: exportOrdersHeaders.processingDate, value: "processedOnDate" },
    { label: exportOrdersHeaders.status, value: "status" },
    { label: exportOrdersHeaders.subStatus, value: "subStatus" },
    { label: exportOrdersHeaders.rejectionReason, value: "rejectionReason" },
    { label: exportOrdersHeaders.supportNote, value: "supportNote" },
    { label: exportOrdersHeaders.endDate, value: "endDate" },
  ];
  return fields;
};

const prepareCSVData = (data) => {
  //TODO: check a way to handle this logic later
  let csvData = data[0].split("\n")[0] + "\n";
  data.forEach((row) => {
    csvData += row.split("\n")[1] + "\n";
  });
  return csvData;
};
const exportCSVFile = (data) => {
  const csvData = prepareCSVData(data);
  const link = document.createElement("a");
  const fileName = "Export Orders.csv";
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csvData)
  );
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export {
  createData,
  toUpperFirstLetter,
  getActivities,
  getUpdatedSummary,
  getFormattedDate,
  getFormattedTime,
  getSelectedOrdersIndices,
  getOrdersStatusCount,
  getStatusTabDetails,
  checkIfOrderSelected,
  handleExportSelectedOrders,
  getDeletedOrdersBEParams,
  getOrderAction,
  openFileInNewTab,
};

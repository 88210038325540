import React, { Fragment } from "react";
import { Box, Stack, Typography, Divider } from "@mui/material";
import Barcode from "react-barcode";

import fincartBlackLogo from "../../../assets/images/fincartBlackLogo.jpeg";
import Styled from "./print-view.styled";
import { adaptDate, getDeliveryData, truncateText } from "./print-view.utils";
import { adaptMobilePhone } from "../../../global.utils";
import PrintLabels from "./components/print-labels";

const PrintPaper = ({ labels, isMobileView, currentRef }) => {

  const FieldBox = ({ id, label, width = "100%", hasDivider = false }) => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          width={width}
          padding={isMobileView ? "5px 10px" : "5px 20px"}
          gap="20px"
        >
          <Typography variant="large" sx={{ wordBreak: 'break-word' }}><PrintLabels labels={label} /></Typography>
          <Typography variant="semiBoldLarge" textAlign="right">
            {id}
          </Typography>
        </Box>
        {hasDivider && <Divider />}
      </>
    )
  };

  const Header = ({ amount, externalId, courierName, sortingCode }) => (
    <Box display="flex" justifyContent="space-between" alignItems="start">
      <Stack
        display="flex"
        gap="10px"
        padding={`${isMobileView ? "5px" : "20px"} 0 0 0`}
      >
        <Styled.FincartImage
          width={isMobileView ? 75 : 150}
          height={isMobileView ? 15 : 30}
          src={fincartBlackLogo}
          isPaperView={true}
        />
        <Typography variant="boldMedium">{`${courierName} ${
          sortingCode && `(${sortingCode})`
        }`}</Typography>
      </Stack>

      {externalId && (
        <Barcode
          value={externalId}
          width={isMobileView ? 1.6 : 2}
          height={isMobileView ? 50 : 70}
          margin={isMobileView ? 0 : 5}
          fontSize={isMobileView ? 10 : 16}
          font="'Poppins', 'Noto Sans Arabic'"
        />
      )}

      <Typography variant="semiBoldXl" paddingTop="10px ">
        COD {amount}
      </Typography>
    </Box>
  );

  const DeliveryInfo = ({ customer, merchantName }) => {
    const addressAndShippingNotes = [truncateText(customer?.address, 250), truncateText(customer?.notes, 250)]

    return (
      <Box display="flex">
        <FieldBox id="العنوان" label={addressAndShippingNotes} width="50%" />

        <Styled.PaperPackageInfoWrapper>
          {getDeliveryData({ merchantName, customer }).map(({ id, label }) => (
            <FieldBox key={id} id={id} label={label} hasDivider />
          ))}
        </Styled.PaperPackageInfoWrapper>
      </Box>
    )
  };

  const PackageInfo = ({ orderPackage }) => {
    const { allowedOpenPackage, numberOfItems, refNumber, desc } =
      orderPackage || {};

    const packageDesc = [`${refNumber? `Order no. ${refNumber}` : ''}`, desc]

    return (
      <Stack minHeight="120px">
        <Box display="flex">
          <Typography
            width="50%"
            variant="semiBoldLarge"
            textAlign="right"
            padding="5px 20px"
          >
            {allowedOpenPackage ? "يسمح بفتح الشحنة" : "لا يسمح بفتح الشحنة"}
          </Typography>

          <Divider orientation="vertical" />
          <Box width="50%">
            <FieldBox id="عدد القطع" label={numberOfItems} />
          </Box>
        </Box>
        <Divider />
        <FieldBox
          id="وصف الشحنة"
          label={packageDesc}
        />
      </Stack>
    );
  };

  const PickupInfo = ({ pickup }) => {
    const { phone, address, area, city } = pickup || {};

    return (
      <Stack minHeight="120px">
        <Box display="flex">
          <FieldBox width="50%" id="تليفون المرتجع" label={adaptMobilePhone(phone)} />
          <FieldBox
            width="50%"
            id="منطقة المرتجع"
            label={`${area} ${area && city && " - "}${city}`}
          />
        </Box>
        <Divider />
        <FieldBox id="عنوان المرتجع" label={address} />
      </Stack>
    );
  };

  const Footer = ({ order }) => {
    const { orderNumber, creationDate } = order || {};

    return (
      <Box display="flex">
        {creationDate && (
          <FieldBox id="تاريخ الانشاء" label={adaptDate(creationDate)} />
        )}
        <Divider orientation="vertical" />
        <FieldBox id="كود الشحنة" label={`${orderNumber}`} />
      </Box>
    );
  };

  return (
    <Styled.PrintLabelsWrapper ref={currentRef} isPaperView={true}>
      {labels.map(
        ({
          externalId,
          amount,
          courierName,
          merchantName,
          sortingCode,
          package: orderPackage,
          customer,
          pickup,
          order,
        }) => (
          <Styled.PrintPaperWrapper key={order?.orderNumber}>
            <Stack display="flex" gap="8px" height="100%">
              <Header
                amount={amount}
                externalId={externalId}
                courierName={courierName}
                sortingCode={sortingCode}
              />
              <Stack border="2px solid black" borderRadius="8px" height="100%">
                <DeliveryInfo merchantName={merchantName} customer={customer} />
                <Divider style={{ borderWidth: 1, borderColor: "black" }} />
                <PackageInfo orderPackage={orderPackage} />
                <Divider style={{ borderWidth: 1, borderColor: "black" }} />
                <PickupInfo pickup={pickup} />
                <Divider style={{ borderWidth: 1, borderColor: "black" }} />
                <Footer order={order} />
              </Stack>
            </Stack>
          </Styled.PrintPaperWrapper>
        )
      )}
    </Styled.PrintLabelsWrapper>
  );
};

export default PrintPaper;

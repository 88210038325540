import React, { useState, useRef } from 'react'
import { Stack, Box, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { components } from "react-select";
import * as XLSX from 'xlsx' //TODO: update to exceljs and remove this library

import { Select } from '../../../common/components/form-field/components/select/select';
import Styled from './upload-file-view.styled'
import { INIT_ROWS, ORDER_TYPES, SUMMARY_IDS } from '../bulk-create-orders.constants';
import { mapCsvFileToFEFormat, verifyUploadedCsvHeaders } from '../bulk-create-order.utils';
import { ImportIcon, PlusIcon } from '../../../assets/icons';
import { ORDERS_IDS } from '../../create-order/create-order.constants';
import BulkUploadExample from '../../../assets/excels/Fincart-Bulk-Upload.xlsx'
import AddPickupLocationDrawer from '../../create-order/add-pickup-location-drawer'


const UploadFileView = ({
  pickupLocations, setData, isSpecialMerchant, theme, setDropDownsOptions, currentPickupLocation, setCurrentPickupLocation,
  dropDownOptions, allAreas, toast, setSummary, setPickupLocations, updateData
}) => {
  const { t } = useTranslation()

  const fileInputRef = useRef(null);

  const [activeOrderType, setActiveOrderType] = useState(ORDERS_IDS.DELIVERY)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const togglePickupLocationDrawer = (open) => { setIsDrawerVisible(open); };

  const onUploadFile = (e, isDropped) => {
    setIsFileUploaded(true)
    e.preventDefault();
    e.stopPropagation();
    const uploadedFile = isDropped? e.dataTransfer?.files[0] : e.target.files[0]
    const fileType = uploadedFile.name.split('.')?.pop()

    if(fileType !== 'xlsx') {
      toast.error(t('bulkCreateOrders.uploadView.invalidFileTypeError'))
      setIsFileUploaded(false)
      return
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(uploadedFile)
    reader.onload = () => {
      const file = reader.result;

      if(file !== null) {
        const workbook = XLSX.read(file, { type: 'buffer' })
        const worksheetName = workbook.SheetNames[0]
        const workSheet = workbook.Sheets[worksheetName]
        const rowObject = XLSX.utils.sheet_to_json(workSheet, { header: 1, defval: "" })
        const headers = rowObject[0];

        if(!verifyUploadedCsvHeaders(headers)) {
          setIsFileUploaded(false);
          return toast.error(t('bulkCreateOrders.uploadView.invalidCsvFileError'));
        }

        const data = XLSX.utils.sheet_to_json(workSheet) || []
        data.splice(0,1)
        if(data?.length === 0) {
          setIsFileUploaded(false);
          return toast.error(t('bulkCreateOrders.uploadView.emptyCsvFileError'));
        }

        const mappedData = mapCsvFileToFEFormat({ data, isSpecialMerchant, dropDownOptions, allAreas })
        setSummary((prevSummary) => ({ ...prevSummary, [SUMMARY_IDS.UPLOADED]: mappedData?.length }))
        const uploadedData = [...mappedData, ...INIT_ROWS.slice(50)]

        if(data?.length > 0) {
          updateData(uploadedData)
        }

        setData(uploadedData)
      }
    };
  }

  const updateOrderType = (key) => {
    setActiveOrderType(key);
  }

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <Typography variant="boldSubtitles">
        {t('bulkCreateOrders.uploadView.pickupLabel')}:
      </Typography>

      {children}
    </components.Control>
  );

  return (
    <Stack paddingTop="4px" display="flex" gap="16px" width="100%" height="100%" onDragOver={(e) => e.preventDefault()}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Typography variant="regular">
          {t('bulkCreateOrders.uploadView.title')}
        </Typography>

        <Box>
          {pickupLocations?.length === 0 ? (
            <Styled.AddPickupLocationButton disableRipple onClick={() => setIsDrawerVisible(true)}>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography variant="boldSubtitles">
                  {t('bulkCreateOrders.uploadView.addPickupLocationButton')}
                </Typography>

                <PlusIcon />
              </Box>
            </Styled.AddPickupLocationButton>
          ) :
            (
              <Select
                noBorders
                noLeftPadding
                options={pickupLocations}
                value={currentPickupLocation}
                onChange={setCurrentPickupLocation}
                height={20}
                width={300}
                isRight
                menuButtonProps={{
                  hasMenuButton: true,
                  text: t('bulkCreateOrders.uploadView.addPickupLocationButton'),
                  onClick: () => setIsDrawerVisible(true)
                }}
                components={{ Control }}
                formatOptionLabel={(value) => (
                  <Box display="flex" gap="4px">
                    <Typography  sx={{ whiteSpace: 'no-wrap' }} variant="boldSubtitles">{value.label}</Typography>
                    <Typography sx={{ whiteSpace: 'no-wrap' }} variant="regular" color="Gray">{value.area}</Typography>
                  </Box>
                )}
              />
            )}
        </Box>
      </Box>

      <Box width="100%" display="flex" gap="12px">
        {ORDER_TYPES.map(({ key, i18nKey, icon: Icon, disabled }) =>
          <Styled.OrderTypeButton
            key={key}
            disabled={disabled}
            isActive={!disabled}
            isSelected={key === activeOrderType}
            disableRipple
            label={(
              <Stack display="flex" gap="4px" justifyContent="center" alignItems="center">
                <Icon />
                <Typography variant="boldSubtitles">
                  {t(`bulkCreateOrders.uploadView.orderTypes.${i18nKey}`)}
                </Typography>
              </Stack>
            )}
            variant="outlined"
            onClick={() => updateOrderType(key)}
          >
          </Styled.OrderTypeButton>
        )}
      </Box>

      <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
        <Styled.SampleFile
          href={BulkUploadExample}
          download="Fincart Bulk Upload.xlsx"
          rel="noreferrer noopener"
        >
          {t('bulkCreateOrders.uploadView.downloadSampleFileButton')}
        </Styled.SampleFile>
      </Box>

      <Styled.UploadPanel
        disableRipple
        onDrop={(e) => onUploadFile(e, true)}
        onClick={() => fileInputRef?.current?.click()}
        disabled={!activeOrderType || isFileUploaded || !currentPickupLocation}
      >
        <Stack display="flex" width="100%" maxWidth="500px" justifyContent="center" alignItems="center" gap="16px">
          <ImportIcon stroke={!activeOrderType? '#c9c9ca': '#7F7D80'}/>
          <Typography variant="largeText">
            {t('bulkCreateOrders.uploadView.uploadPanelLabel')}
          </Typography>
        </Stack>
      </Styled.UploadPanel>

      <input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        hidden
        ref={fileInputRef}
        onChange={onUploadFile}
        type="file"
      />

      {/* TODO: investigate if we want to update dropdown options too */}
      <AddPickupLocationDrawer
        areas={allAreas}
        dropDownsOptions={dropDownOptions}
        isDrawerVisible={isDrawerVisible}
        setPickupLocations={setPickupLocations}
        theme={theme}
        t={t}
        toggleDrawer={togglePickupLocationDrawer}
        setDropDownsOptions={setDropDownsOptions}
        setFormValue={(name, value) => setCurrentPickupLocation(value)}
      />
    </Stack>
  )
}

export default UploadFileView